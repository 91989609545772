.header-service {
    margin-top: 22px;
    .ant-card {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 8px;
        .ant-card-body {
            padding: 0;
            margin: 12px 6px;
            max-height: 320px;
            overflow-y: auto;
        }
    }
}
.list-service__common {
    margin-right: 20px;
}