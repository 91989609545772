#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ck-editor__editable {
  min-height: 200px;
}
.ck-editor__editable {
  max-height: 350px;
}
.price-device {
  .ant-input-group-addon {
    width: 80px;
    padding: 0;
  }
  .ant-input-group {
    height: 32px;
  }
}
th.custom-action-position {
  text-align: center !important;
}
.disable-icon {
  color: #6c757d;
  &:hover {
    color: #6c757d !important;
  }
}
.filter.space-x-6 {
  .icon-date {
    margin-left: 5px !important;
    margin-right: -20px !important;
  }
}
.select-role {
  .ant-select-arrow {
    top: 33%;
  }
}
