.nav-profile {
    min-width: 9.5rem;
    margin-top: 22px;
    .nav-profile-header {
        padding: 0.7rem 1rem;
        border-bottom: 1px solid #e6ebf1;
    }
    .nav-profile-body {
        padding: 0.625rem 0;
    }
}

.nav-dropdown {
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    border-radius: 0.625rem;
}
.profile-items {
    background: transparent;
    border-bottom: transparent;
    &:focus-visible {
        box-shadow: none !important;
    }
    .menu-item:hover {
        color: fff;
    }
}
