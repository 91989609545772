.h-100 {
  height: 100%;
}
.my-4 {
  margin: 25px 0;
}
.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 9px;
  padding-left: 9px;
}
.justify-content-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.d-flex {
  display: flex;
}
.googleBtn > div {
  margin-top: 4px;
}

.googleBtn {
  width: 180px;
  border-radius: 0 !important
}

.section-login__Vid {
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
  &:hover {
    opacity: .9;
  }
}
.logo-login{
  width: unset;
}

.ant-image{
  width: unset;
}
.border-custom{
  border:rgba(0, 0, 0, 0.3);
  border-radius: 4px
}
