.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nav-right {
    margin-right: 10px;
  }
}
.btn-logout {
  margin-right: 10px;
}
.brand-name {
  color: #f1f1f1;
  text-transform: uppercase;
  padding: 1.1rem 0.5rem;
  font-size: 20px;
  font-weight: bold;
}
.ant-menu-item {
  margin-top: 0 !important;
}
