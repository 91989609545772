.ant-image-mask-info {
  font-size: 18px;
}

.ant-col.ant-form-item-label {
  min-height: 32px !important;
}

.ant-input:hover, 
.ant-input:focus, 
.ant-picker-focused, 
.ant-picker:hover, 
.ant-select.ant-select-in-form-item:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input-number:hover {
  border-color: #d9d9d9 !important;
}
.ant-input-group-addon {
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: transparent !important;
  }
}
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-placement-rightTop {
  z-index: -1 !important;
}
.ant-menu-submenu-selected {
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-placement-rightTop {
    z-index: -1 !important;
  }
}

.ant-menu-submenu-active,
.ant-menu-submenu-selected.ant-menu-submenu-active {
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-placement-rightTop {
    z-index: 9999999999999999 !important;
  }
}